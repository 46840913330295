<template>
<div class="service">
  <div class="bg">
    <div class="info">
      <h3>用心为客户提供支持与服务</h3>
      <p>您的成功高于一切</p>
    </div>
  </div>
  <div class="list">
    <ul class="container">
      <li>
        <div class="tit">常见问题</div>
        <p>关于黑洞引擎及星云平台常见问题，您可以在咨询技术工程师之前预先浏览是否有和您相同的问题及解答。</p>
        <!-- <span @click="to('question')">问答中心</span> -->
        <!-- <span>问答中心</span> -->
      </li>
      <li>
        <div class="tit">视频讲解</div>
        <p>视频讲解中包含黑洞引擎及星云平台操作视频，可以给您提供更为直观的了解黑洞引擎及星云平台的方法。</p>
        <span @click="to('explain')">浏览视频内容</span>
      </li>
      <li>
        <div class="tit">专属支持</div>
        <p>专属支持是由秉匠科技技术总监直接为您提供技术帮助，本服务只针对黑洞引擎及星云平台定制客户及专属合作伙伴开放。我们将在收到申请后24小时内为您提供专属服务。</p>
        <!-- <span @click="to('contactus')">联系我们</span> 1/12市场要求隐藏此按钮-->
      </li>
      <li>
        <div class="tit">文档</div>
        <p>我们丰富的文档资源可同时满足初学者和资深用户的需求，包含所有黑洞引擎操作手册、虫洞编辑器和API接口的相关指南与参考文档。</p>
        <span><a target="_blank" href="https://developer.bjblackhole.com/">开发者中心</a></span>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    to (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.service{
  color: #fff;
  .bg{
    width: 100%;
    height: 60vh;
    background: url('../../assets/image/service/bg.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
    .info{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      h3{
        font-size: 50px;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 20px;
        }
      }
      p{
        font-size: 24px;
        line-height: 50px;
        @media screen and (max-width:768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .list{
    background: #000;
    ul{
      // background-color: pink;
      padding: 50px 30px 10px;
      overflow: hidden;
      li{
        background-color: #232323;
        width: calc(50% - 40px);
        float: left;
        height: 218px;
        border-radius: 5px;
        padding: 20px 30px;
        margin-bottom: 50px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 30px auto;
        }
        .tit{
          font-size: 22px;
          // line-height: 60px;
          margin: 20px 0;
        }
        p{
          min-height: 60px;
        }
        span{
          color: #0aaff1;
          margin-top: 30px;
          display: block;
          cursor: pointer;
          a{
            color: #0aaff1;
          }
        }
      }
      li:nth-child(odd){
        margin-right: 80px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 30px auto;
        }
      }
    }
  }
}
</style>
